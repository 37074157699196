import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import * as styles from './intro.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import { sendEvent } from '@src/utils/amplitude';
import arrowDown from '@src/assets/images/landing/arrow-down.svg';
import LoginPopup from '@components/molecules/LoginPopup';
import { mixPanelService } from '../../../../services/mixPanelService';
import { isBrowser } from '@src/utils/ssr';

import UrlBuilder from '@src/utils/urlBuilder';

const Intro = ({ isLoginPopupOpen, setLoginPopupOpen }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	const [currentIndex, setCurrentIndex] = useState(0);
	const startTouchPosition = useRef(0);
	const currentTouchPosition = useRef(0);

	const [pageWidth, setPageWidth] = useState(0);
	const [signUpUrl, setSignUpUrl] = useState('');

	useEffect(() => {
		const handleResize = () => {
			setPageWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const slidesCount = 5;
	const width = 264;
	const gap = 8;
	const totalWidth = width * slidesCount;
	const offset = pageWidth - totalWidth < 0 ? pageWidth - totalWidth - 26 : 0;

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesCount);
	};

	const prevSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + slidesCount) % slidesCount);
	};

	const handleTouchStart = (e) => {
		startTouchPosition.current = e.touches[0].clientX;
	};

	const handleTouchMove = (e) => {
		currentTouchPosition.current = e.touches[0].clientX;
	};

	const handleTouchEnd = () => {
		if (startTouchPosition.current - currentTouchPosition.current > 50) {
			nextSlide();
		}

		if (startTouchPosition.current - currentTouchPosition.current < -50) {
			prevSlide();
		}
	};

	const handleMouseDown = (e) => {
		startTouchPosition.current = e.clientX;
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};

	const handleMouseMove = (e) => {
		currentTouchPosition.current = e.clientX;
	};

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);

		if (startTouchPosition.current - currentTouchPosition.current > 50) {
			nextSlide();
		}

		if (startTouchPosition.current - currentTouchPosition.current < -50) {
			prevSlide();
		}
	};

	const setUpLinks = () => {
		const appUrl = process.env.GATSBY_APP_URL;

		const signUpLink = new UrlBuilder(`${appUrl}/signup`);

		setSignUpUrl(signUpLink.build());
	};

	useEffect(() => {
		if (isBrowser) {
			setUpLinks();
		}
	}, []);

	const picLang = intl.locale === 'ru' ? '' : '-En';

	return (
		<section className={[styles.container, 'container-v'].join(' ')}>
			<div className={styles.content}>
				<div className={styles.text}>
					<h1 className={[styles.title, styles[intl.locale]].join(' ')}>
						{intl.formatMessage({ id: 'videolanding.intro.title' })}
					</h1>
					<p className={styles.subtitle}>{intl.formatMessage({ id: 'videolanding.intro.subtitle' })}</p>
					<div className={styles.buttonWrapper}>
						<a href={signUpUrl}>
							<button
								href={signUpUrl}
								className={[styles.tryBtn].join(' ')}
								onClick={() => {
									// setLoginPopupOpen(true);
									sendEvent('New Landing / Click Try button', {
										Location: '1st screen',
									});
									mixPanelService.sendEvent(
										{
											event: 'Landing / Click Try Button',
											properties: {
												Location: '1st screen',
											},
										},
										account?.userData?.steamAccountId || null
									);
								}}
							>
								{intl.formatMessage({ id: 'videolanding.intro.button' })}
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className={styles.firstScreen_row}>
				<div className={styles.firstScreen_item}>
					<div className={styles.firstScreen_title}>
						{intl.formatMessage({ id: 'videolanding.introcards.stats.title' })}
					</div>
					<div className={styles.firstScreen_desc}>
						{intl.formatMessage({ id: 'videolanding.introcards.stats.desc' })}
					</div>
					<div className={styles.firstScreen_img_small}>
						<img src={`/landing/first-screen/First-Screen-Stats${picLang}.svg`} alt="" />
					</div>
				</div>
				<div className={styles.firstScreen_item}>
					<div className={styles.firstScreen_title}>
						{intl.formatMessage({ id: 'videolanding.introcards.progress.title' })}
					</div>
					<div className={styles.firstScreen_desc}>
						{intl.formatMessage({ id: 'videolanding.introcards.progress.desc' })}
					</div>
					<div className={styles.firstScreen_img}>
						<img src={`/landing/first-screen/First-Screen-Progress${picLang}.svg`} alt="" />
					</div>
				</div>
				<div className={styles.firstScreen_item}>
					<div className={styles.firstScreen_title}>
						{intl.formatMessage({ id: 'videolanding.introcards.performance.title' })}
					</div>
					<div className={styles.firstScreen_desc}>
						{intl.formatMessage({ id: 'videolanding.introcards.performance.desc' })}
					</div>
					<div className={styles.firstScreen_img}>
						<img src={`/landing/first-screen/First-Screen-Performance${picLang}.svg`} alt="" />
					</div>
				</div>
				<div className={styles.firstScreen_item}>
					<div className={styles.firstScreen_title}>
						{intl.formatMessage({ id: 'videolanding.introcards.analyze.title' })}
					</div>
					<div className={styles.firstScreen_desc}>
						{intl.formatMessage({ id: 'videolanding.introcards.analyze.desc' })}
					</div>
					<div className={styles.firstScreen_img}>
						<img src={`/landing/first-screen/First-Screen-Analyze${picLang}.svg`} alt="" />
					</div>
				</div>
				<div className={styles.firstScreen_item}>
					<div className={styles.firstScreen_title}>
						{intl.formatMessage({ id: 'videolanding.introcards.clips.title' })}
					</div>
					<div className={styles.firstScreen_desc}>
						{intl.formatMessage({ id: 'videolanding.introcards.clips.desc' })}
					</div>
					<div className={styles.firstScreen_iframe}>
						<video autoPlay muted loop>
							<source src="/video/first-screen/Clip.mp4" type="video/mp4" />
							<source src="/video/first-screen/Clip.webm" type="video/webm" />
						</video>
					</div>
				</div>
			</div>

			<div
				className={styles.carousel}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				onMouseDown={handleMouseDown}
			>
				<div
					className={styles.carouselInner}
					style={{ transform: `translateX(-${currentIndex * (width + gap - gap)}px)` }}
				>
					<div className={styles.firstScreen_item}>
						<div className={styles.firstScreen_title}>
							{intl.formatMessage({ id: 'videolanding.introcards.stats.title' })}
						</div>
						<div className={styles.firstScreen_desc}>
							{intl.formatMessage({ id: 'videolanding.introcards.stats.desc' })}
						</div>
						<div className={styles.firstScreen_img_small}>
							<img src="/landing/first-screen/First-Screen-Stats.svg" alt="" />
						</div>
					</div>
					<div className={styles.firstScreen_item}>
						<div className={styles.firstScreen_title}>
							{intl.formatMessage({ id: 'videolanding.introcards.progress.title' })}
						</div>
						<div className={styles.firstScreen_desc}>
							{intl.formatMessage({ id: 'videolanding.introcards.progress.desc' })}
						</div>
						<div className={styles.firstScreen_img}>
							<img src="/landing/first-screen/First-Screen-Progress.svg" alt="" />
						</div>
					</div>
					<div className={styles.firstScreen_item}>
						<div className={styles.firstScreen_title}>
							{intl.formatMessage({ id: 'videolanding.introcards.performance.title' })}
						</div>
						<div className={styles.firstScreen_desc}>
							{intl.formatMessage({ id: 'videolanding.introcards.performance.desc' })}
						</div>
						<div className={styles.firstScreen_img}>
							<img src="/landing/first-screen/First-Screen-Performance.svg" alt="" />
						</div>
					</div>
					<div className={styles.firstScreen_item}>
						<div className={styles.firstScreen_title}>
							{intl.formatMessage({ id: 'videolanding.introcards.analyze.title' })}
						</div>
						<div className={styles.firstScreen_desc}>
							{intl.formatMessage({ id: 'videolanding.introcards.analyze.desc' })}
						</div>
						<div className={styles.firstScreen_img}>
							<img src="/landing/first-screen/First-Screen-Analyze.svg" alt="" />
						</div>
					</div>
					<div className={styles.firstScreen_item}>
						<div className={styles.firstScreen_title}>
							{intl.formatMessage({ id: 'videolanding.introcards.clips.title' })}
						</div>
						<div className={styles.firstScreen_desc}>
							{intl.formatMessage({ id: 'videolanding.introcards.clips.desc' })}
						</div>
						<div className={styles.firstScreen_iframe}>
							<video autoPlay muted loop playsInline preload="auto">
								<source src="/video/first-screen/Clip.mp4" type="video/mp4" />
								<source src="/video/first-screen/Clip.webm" type="video/webm" />
							</video>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.bottomWrapper}>
				<div className={styles.bottom}>
					<a
						href={signUpUrl}
						className={[styles.tryBtn].join(' ')}
						onClick={() => {
							// setLoginPopupOpen(true);
							sendEvent('New Landing / Click Try button', {
								Location: '1st screen',
							});
							mixPanelService.sendEvent(
								{
									event: 'Landing / Click Try Button',
									properties: {
										Location: '1st screen',
									},
								},
								account?.userData?.steamAccountId || null
							);
						}}
					>
						{intl.formatMessage({ id: 'videolanding.intro.button' })}
					</a>
				</div>

				{/*<AnchorLink href="#presentation" className={styles.scrollBtn}>
					<img src={arrowDown} className={styles.scrollBtnIcon} alt="arrow down" />
				</AnchorLink>*/}
			</div>

			<LoginPopup isOpen={isLoginPopupOpen} onClose={() => setLoginPopupOpen(false)} />
		</section>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
